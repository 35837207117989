import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Image, Modal, Row} from 'react-bootstrap';
import React, {Component} from "react";
import * as Yup from "yup";
import {Formik, useField, useFormikContext} from "formik";
import axios from "axios";
import {POSTAL, S_INSTNT_FINGER_PRINT, S_INSTNT_TXN_ID, URL_BIOMETRICS, URL_PROVINCE} from "../lib/Constants";
import publicIp from "public-ip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format, getMonth, getYear, parse} from "date-fns";
import {STRINGS} from "../lib/Strings";
import Layout from "../components/Layout";
import moment from 'moment';
import MaskedInput from "react-text-mask";
import {isMobile} from "react-device-detect";
import {InstntSignupProvider} from "@instnt/instnt-react-js";
import {scroller} from "react-scroll";

const nameRegExp = /^[a-zA-ZàâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’\-]+(?: [a-zA-ZàâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ'’\-]+)*$/;
const CApostalCodeRegExp = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .matches(nameRegExp, STRINGS.REVIEW.ERROR_CODE.NUMBERS_ERROR)
        .required(STRINGS.REVIEW.ERROR_CODE.FIRST_NAME_REQUIRED),
    middleName: Yup.string()
        .nullable()
        .matches(nameRegExp, STRINGS.REVIEW.ERROR_CODE.NUMBERS_ERROR),
    surName: Yup.string()
        .nullable()
        .matches(nameRegExp, STRINGS.REVIEW.ERROR_CODE.NUMBERS_ERROR)
        .required(STRINGS.REVIEW.ERROR_CODE.LAST_NAME_REQUIRED),
    dob: Yup.string()
        .nullable()
        .required(STRINGS.REVIEW.ERROR_CODE.DOB_REQUIRED),
    physicalAddress: Yup.string()
        .nullable()
        .required(STRINGS.REVIEW.ERROR_CODE.ADDRESS_REQUIRED),
    city: Yup.string()
        .nullable()
        .matches(nameRegExp, STRINGS.REVIEW.ERROR_CODE.NUMBERS_ERROR)
        .required(STRINGS.REVIEW.ERROR_CODE.CITY_REQUIRED),
    province: Yup.string()
        .nullable()
        .required(STRINGS.REVIEW.ERROR_CODE.PROVINCE_REQUIRED)
        .notOneOf(["placeholderProvince"], STRINGS.REVIEW.ERROR_CODE.PROVINCE_REQUIRED),
    zip: Yup.string()
        .nullable()
        .matches(CApostalCodeRegExp, STRINGS.REVIEW.ERROR_CODE.POSTAL_CODE_BAD_FORMAT)
        .required(STRINGS.REVIEW.ERROR_CODE.POSTAL_CODE_REQUIRED),
});

const range = (start, end) => {
    const length = end - start;
    return Array.from({length}, (_, i) => end - i);
}

export const DatePickerField = ({...props}) => {
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props);
    const years = range(getYear(props.minDate), getYear(props.maxDate));
    const months = [
        STRINGS.MONTHS.JAN,
        STRINGS.MONTHS.FEB,
        STRINGS.MONTHS.MAR,
        STRINGS.MONTHS.APR,
        STRINGS.MONTHS.MAY,
        STRINGS.MONTHS.JUN,
        STRINGS.MONTHS.JUL,
        STRINGS.MONTHS.AUG,
        STRINGS.MONTHS.SEP,
        STRINGS.MONTHS.OCT,
        STRINGS.MONTHS.NOV,
        STRINGS.MONTHS.DEC
    ];
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled
                                 }) => (
                <div>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select
                        value={getYear(date)}
                        onChange={({target: {value}}) => changeYear(value)}
                    >
                        {years.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[getMonth(date)]}
                        onChange={({target: {value}}) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}
        />
    );
};
export default class Review extends Component {


    constructor(props) {
        super(props);
        this.state = {
          verifyError: null,
          showModal: false,
          showSpinner: false,
          frontImage: null,
          firstName: "",
          middleName: "",
          dob: "",
          physicalAddress: "",
          city: "",
          province: "",
          zip: "",
          email: "",
          surName: "",
          loadInstntData: true,
          disableEdit: false,
          allowContinue: true,
          instData: null,
          provinceList: [],
          minDate: moment(new Date()).subtract(100, 'y').toDate(),
          maxDate: moment(new Date()).subtract(18, 'y').toDate(),
          idType: "",
          instnttxnid: null,
          fingerprint: null,
          activeButton: "",
        };
    
        this.infoNotCorrect = this.infoNotCorrect.bind(this);
        this.infoCorrect = this.infoCorrect.bind(this);
      }


    componentDidMount() {
        console.log("componentDidMount");
        const {history} = this.props;
        const token = sessionStorage.getItem("TOKEN");
        const instnttxnid = sessionStorage.getItem(S_INSTNT_TXN_ID);
        const fingerprint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
        if (instnttxnid) {
            this.setState({instnttxnid, fingerprint});
        }
        if (!token) {
            history.push("/");
        } else {
            const img = sessionStorage.getItem("SELFIE_IMAGE");
            this.setState({
                frontImage: img
            });
            axios.get(URL_PROVINCE, {}).then((result) => {
                let provinceList = [];
                provinceList = result.data.map((elem, idx) => {
                    return (
                        <option
                            value={elem.code}
                            key={elem.code + "-" + elem.description}
                            eventKey={idx}
                            className="[ dropdown__option ]"
                        >
                            {elem.description}
                        </option>
                    );
                });
                this.setState({
                    provinceList
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("componentDidUpdate");
        const {loadInstntData} = this.state;
        if (loadInstntData) {
            const currentInstntData = sessionStorage.getItem("INSTNT_DATA");
            const idType = sessionStorage.getItem("ID_TYPE");
            if (currentInstntData) {
                const instData = JSON.parse(currentInstntData);
                console.log("loading instData", instData.firstName);
                this.setState({
                    firstName: instData.firstName || "",
                    middleName: instData.middleName || "",
                    surName: instData.surName || "",
                    dob: instData.dateOfBirth ? parse(instData.dateOfBirth, "yyyy-MM-dd", new Date()) : "",
                    physicalAddress: instData.address || "",
                    city: instData.city || "",
                    province: instData.state || "",
                    zip: instData.postalCode || "",
                    loadInstntData: false,
                    disableEdit: idType === "Passport" ? false : true,
                    allowContinue: idType === "Passport" ? true : false,
                    instData,
                    idType,
                })
            }
        }
    }

    formURL = (url, paramList) => {
        return url.replace(/%(\d+)/g, (_, n) => paramList[+n - 1]);
    };

    async submitInfo(firstName, middleName, surName, dob, physicalAddress, city, province, zip) {
        const {instData, instnttxnid, fingerprint} = this.state;
        const {history} = this.props;
        this.setState({
            showSpinner: true
        });
        console.log("handleSubmit", firstName, middleName, surName, dob, physicalAddress, city, province, zip);
        let ipAddress = await publicIp.v4();
        const url = this.formURL(URL_BIOMETRICS, [sessionStorage.getItem("OTP_ID")])
        const token = sessionStorage.getItem("TOKEN");
        axios.post(url, {
            instnttxnid,
            fingerprint: fingerprint || window.instnt.fingerprint_txt,
            firstName,
            middleName,
            surName,
            dob: format(dob, "yyyy-MM-dd"),
            physicalAddress,
            city,
            "state": province,
            zip,
            ipAddress,
            "email": sessionStorage.getItem("EMAIL"),
            "mobileNumber": sessionStorage.getItem("PHONE"),
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            sessionStorage.setItem("END_FLAG", "SUCCESS");
            history.push("/end");
        }).catch(error => {
            console.log(error);
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end");
        }).finally(() => {
            if (this._ismounted) {
                this.setState({
                    showSpinner: false
                });
            }
        });

    }

    // Handle when the "No" button is clicked
    infoNotCorrect() {
        const { activeButton } = this.state;
        if (activeButton !== "no") {
        console.log("incorrect");
        this.setState({
            disableEdit: false,
            allowContinue: true,
            activeButton: "no",
        }, () => {
            // Ensure the state is updated before scrolling
            console.log("Scrolling to myScrollElement");
            scroller.scrollTo("myScrollElement", {
            duration: 500,
            delay: 0,
            smooth: true,
            // offset: -32,
            });
        });
        }
    }

    // Handle when the "Yes" button is clicked
    infoCorrect() {
        const { activeButton } = this.state;
        if (activeButton !== "yes") {
        console.log("correct");
        this.setState({
            disableEdit: true,
            allowContinue: true,
            activeButton: "yes",
            });
        }
    }

    handleClose() {
        const {history} = this.props;
        history.push("/");
    }

    setDOB = (value) => {
        this.setState({
            dob: value
        });
    }

    onEventHandler = (event) => {
        console.log("Instnt event: ", event);
        switch (event.type) {
            case "transaction.initiated":
                const fingerprint = document.getElementById("fingerprint_txt");

                this.setState({
                    insnt: event.data.instnt,
                    instnttxnid: event.data.instnt.instnttxnid,
                    fingerprint: fingerprint?.value ? fingerprint.value : event.data.instnt.fingerprint_txt,
                });
                console.log("new instnttxnid", event.data.instnt.instnttxnid);
                break;
            default:
                console.log("unhandled instnt event ", event);
        }
    };

    render() {
        console.log("render");
        const {
            showSpinner,
            showModal,
            frontImage,
            firstName,
            middleName,
            surName,
            dob,
            physicalAddress,
            city,
            province,
            zip,
            disableEdit,
            allowContinue,
            provinceList,
            minDate,
            maxDate,
            idType,
            instnttxnid,
            activeButton,
        } = this.state;
        const {history} = this.props;
        return (
            <Layout showSpinner={showSpinner} history={history}>
                {!instnttxnid && (
                    <InstntSignupProvider
                        formKey={process.env.REACT_APP_INSTNT_KEY}
                        onEvent={this.onEventHandler}
                        serviceURL={process.env.REACT_APP_INSTNT_URL}
                        idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
                    >
                    </InstntSignupProvider>
                )}
                <Modal
                    show={showModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.handleClose.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {STRINGS.MODAL_SUBMIT_SUCCESS.HEADER_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src="/check.png"/>
                        <p>{STRINGS.MODAL_SUBMIT_SUCCESS.BODY_0}</p>
                        <p>{STRINGS.MODAL_SUBMIT_SUCCESS.BODY_1}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName,
                        middleName,
                        surName,
                        dob,
                        physicalAddress,
                        city,
                        province,
                        zip
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        console.log("testing");
                        setSubmitting(true);
                        this.submitInfo(values.firstName, values.middleName, values.surName, values.dob, values.physicalAddress, values.city, values.province, values.zip);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Container className="form-fields">
                                {!isMobile && (
                                    <Row>
                                        <Col sm={12}><h1>{STRINGS.REVIEW.DESKTOP.HEADER_TITLE}</h1></Col>
                                        <Col sm={12}><h3>{STRINGS.REVIEW.DESKTOP.SUBTITLE}</h3></Col>
                                    </Row>
                                )}
                                {isMobile && (
                                    <> 
                                        <Row>
                                            <Col sm={12}><h1>{STRINGS.REVIEW.MOBILE.HEADER_TITLE}</h1></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="text-center">
                                                <Image className="selfieImageReview" src={frontImage ? frontImage : "/selfie.svg"} roundedCircle></Image>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>{STRINGS.REVIEW.MOBILE.BODY_0}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>{STRINGS.REVIEW.MOBILE.BODY_1}</Col>
                                        </Row>
                                        <Row name="myScrollElement">
                                            <Col sm={12}>
                                            {!disableEdit && (
                                                <div className="verify-message">
                                                    {STRINGS.REVIEW.NOT_PASSPORT.UPDATE_INFO}
                                                </div>
                                            )}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <Col sm={12}><h2>{STRINGS.REVIEW.SECTION_HEADER_PERSONAL}</h2></Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group controlId="formCode">
                                            <Form.Label>{STRINGS.REVIEW.FORM.FIRST_NAME_LABEL}</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="firstName"
                                                          placeholder={STRINGS.REVIEW.FORM.FIRST_NAME_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.firstName}
                                                          className={touched.firstName && errors.firstName ? "has-error" : null}/>
                                            {touched.firstName && errors.firstName ? (
                                                <div className="error-message">{errors.firstName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formMiddleName">
                                            <Form.Label>{STRINGS.REVIEW.FORM.MIDDLE_NAME_LABEL}</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="middleName"
                                                          placeholder={STRINGS.REVIEW.FORM.MIDDLE_NAME_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.middleName}
                                                          className={touched.middleName && errors.middleName ? "has-error" : null}/>
                                            {touched.middleName && errors.middleName ? (
                                                <div className="error-message">{errors.middleName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formLastName">
                                            <Form.Label>{STRINGS.REVIEW.FORM.LAST_NAME_LABEL}</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="surName"
                                                          placeholder={STRINGS.REVIEW.FORM.LAST_NAME_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.surName}
                                                          className={touched.surName && errors.surName ? "has-error" : null}/>
                                            {touched.surName && errors.surName ? (
                                                <div className="error-message">{errors.surName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formDOB">
                                            <Form.Label>{STRINGS.REVIEW.FORM.DOB_LABEL}</Form.Label>
                                            <DatePickerField name="dob" id="dob"
                                                             showYearDropdown
                                                             showMonthDropdown
                                                             minDate={minDate}
                                                             maxDate={maxDate}
                                                             yearDropdownItemNumber={60}
                                                             placeholderText={STRINGS.REVIEW.FORM.DOB_PLACEHOLDER}
                                                             selected={values.dob}
                                                             disabled={disableEdit}
                                                             onBlur={handleBlur}
                                                             dateFormat="yyyy-MM-dd"
                                                             onChange={(date) => this.setDOB(date)}
                                                             className="[datepicker form-control form-control-lg ]"/>
                                            {touched.dob && errors.dob ? (
                                                <div className="error-message">{errors.dob}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}><h2>{STRINGS.REVIEW.SECTION_HEADER_ADDRESS}</h2></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="formAddress">
                                            <Form.Label>{STRINGS.REVIEW.FORM.STREET_ADDRESS_LABEL}</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="physicalAddress"
                                                          placeholder={STRINGS.REVIEW.FORM.STREET_ADDRESS_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.physicalAddress}
                                                          className={touched.physicalAddress && errors.physicalAddress ? "has-error" : null}/>
                                            {touched.physicalAddress && errors.physicalAddress ? (
                                                <div className="error-message">{errors.physicalAddress}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formCity">
                                            <Form.Label>{STRINGS.REVIEW.FORM.CITY_LABEL}</Form.Label>
                                            <Form.Control size="lg"
                                                          disabled={disableEdit}
                                                          type="text"
                                                          name="city"
                                                          placeholder={STRINGS.REVIEW.FORM.CITY_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.city}
                                                          className={touched.city && errors.city ? "has-error" : null}/>
                                            {touched.city && errors.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="selectId">
                                            <Form.Label>{STRINGS.REVIEW.FORM.PROVINCE_LABEL}</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                as="select"
                                                name="province"
                                                disabled={disableEdit}
                                                value={values.province}
                                                className={touched.province && errors.province ? "has-error" : null}
                                                onChange={handleChange}
                                            >
                                                <option className="[ dropdown__option ]" value="placeholderProvince">
                                                    {STRINGS.REVIEW.FORM.PROVINCE_PLACEHOLDER}
                                                </option>
                                                {provinceList}
                                            </Form.Control>
                                            {touched.province && errors.province ? (
                                                <div className="error-message">{errors.province}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formPostal">
                                            <Form.Label>{STRINGS.REVIEW.FORM.POSTAL_CODE_LABEL}</Form.Label>
                                            <MaskedInput
                                                disabled={disableEdit}
                                                mask={POSTAL.CA_POSTALMASK}
                                                className={touched.zip && errors.zip ? "form-control form-control-lg has-error" : "form-control form-control-lg"}
                                                placeholder= {STRINGS.REVIEW.FORM.POSTAL_CODE_PLACEHOLDER}
                                                guide={false}
                                                id="zip"
                                                name="zip"
                                                value={values.zip}
                                                onChange={handleChange}
                                            />
                                            {touched.zip && errors.zip ? (
                                                <div className="error-message">{errors.zip}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isMobile && idType !== "Passport" && (
                                    <>
                                        <Row>
                                            <Col sm={12} className="text-center verify-title"><h2>{STRINGS.REVIEW.NOT_PASSPORT.HEADER_TITLE}</h2></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <Button
                                                    variant="secondary"
                                                    className={`verify-btn ${activeButton === "yes" ? "clicked" : ""}`}
                                                    block
                                                    size="lg"
                                                    type="button"
                                                    onClick={this.infoCorrect}
                                                    disabled={activeButton === "no"}
                                                    aria-pressed={activeButton === "yes"}
                                                    >
                                                    {STRINGS.REVIEW.NOT_PASSPORT.YES}
                                                </Button>
                                            </Col>
                                            <Col xs={6}>
                                                <Button
                                                variant="secondary"
                                                className={`verify-btn ${activeButton === "no" ? "clicked" : ""}`}
                                                block
                                                size="lg"
                                                type="button"
                                                onClick={this.infoNotCorrect}
                                                disabled={activeButton === "yes"}
                                                aria-pressed={activeButton === "no"}
                                                >
                                                {STRINGS.REVIEW.NOT_PASSPORT.NO}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/* {!disableEdit && (

                                            <span className="verify-message">{STRINGS.REVIEW.NOT_PASSPORT.UPDATE_INFO}</span>

                                        )} */}
                                    </>
                                )}
                                <Row className="submit-btn-container">
                                    <Col md={{span: 6, offset: 3}}>
                                        <Button type="submit" variant="primary" block size="lg"
                                                disabled={isSubmitting || !allowContinue}>
                                            {STRINGS.REVIEW.CONTINUE_CTA}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
                <br/>
                <br/>
            </Layout>
        );
    }
}