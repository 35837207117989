import {ERROR_CODE, FIInformation} from "./Constants";

export const STRINGS = {
    MAIN: {
        HEADER_TITLE: `Welcome to ${FIInformation.FI_DISPLAY_NAME}'s online identity verification`,
        SUBHEADER_TITLE: `In order to verify your identity you will need:`,
        MOBILE_INFO_CARD: `your mobile device`,
        ID_INFO_CARD: `a valid government-issued identity document`,
        SELFIE_INFO_CARD: `be prepared to take a selfie and photos of your ID`,
        ENVIRONMENT_INFO_CARD: `to be sure you are in a well-lit area`,
        CONTINUE_CTA: `Let's begin`,
    },
    INFO: {
        HEADER_TITLE: `Identity verification`,
        SUBHEADER_TITLE: `Enter your primary email address and mobile number.`,
        EMAIL_LABEL: `Your email address`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `Your mobile no.`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `By continuing you confirm that you have read and agree to the`,
        TERMS_LINK_LABEL_1: `terms and conditions.`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `Continue`,
        ERROR_CODE: {
            EMAIL_REQUIRED:`*Email is required`,
            EMAIL_CHARACTER_LIMIT:`*Email must be less than 100 characters`,
            EMAIL_VALID:`*Must be a valid email address`,
            PHONE_VALID:`*Phone number is not valid`,
            PHONE_REQUIRED:`*Phone number required`,
            TERMS_REQUIRED:`The terms and conditions must be accepted.`,
        },
    },
    VERIFY: {
        HEADER_TITLE: `Mobile phone verification`,
        SUBHEADER_TITLE: `We've sent a verification code to the mobile number you provided. Enter the verification code to continue.`,
        OTP_LABEL: `Verification code`,
        OTP_PLACEHOLDER: `code`,
        RESEND_TITLE: `Didn't receive a code?`,
        RESEND_OTP: `Resend Code`,
        CONTINUE_CTA: `Continue`,
        ERROR: {
            OTP_INLINE_ERROR: `Invalid verification code`,
            ERROR_TITLE: `Error!`,
            ERROR_BODY_0: `The verification code you entered is invalid.`,
            ERROR_BODY_1: `Ensure the code is entered correctly; otherwise, request a new one.`,
        },
        
    },
    ID_SELECT: {
        HEADER_TITLE: "Select your ID type",
        ID_COMPATIBLE: "Be sure your ID is compatible",
        ID_SELECT_LABEL: "Select your govt. issued ID",
        DUMMY_OPTION: "Select an ID type",
        CONTINUE_CTA: `Continue`,
        SPINNER_MSG: `We need about 10 seconds or so to verify your ID... Please wait!`,
        OVERLAY_FRONT_ID: `Align your ID and tap to capture.`,
        OVERLAY_FRONT_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_BACK_ID: `Align your ID and tap to capture.`,
        OVERLAY_BACK_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_SELFIE: `Align your face and tap to capture.`,
        OVERLAY_SELFIE_AUTOTAKE: `Align your face within the box and hold steady.`,
        VERIFICATION_0: `Verifying your identity... please wait.`,
        VERIFICATION_1: `Validating ID to facial biometrics... please wait.`,
        VERIFICATION_2: `Validating social biometrics... please wait.`,
        ERROR: {
            ERROR_TITLE: `Error!`,
            ERROR_BODY: `Error encountered processing your info...`,
            ERROR_SELECT_ID: `Please select a valid ID type.`,
            ERROR_FRONT_ID: `Please capture the front of your id.`,
            ERROR_BACK_ID: `Please capture the back of your id.`,
            ERROR_SELFIE: `Please capture a selfie.`,
            ERROR_LANDSCAPE: `Landscape orientation is not supported. Rotate your device to a portrait orientation.`,
            ERROR_PORTRAIT: `Portrait orientation is not supported. <br/>Rotate your device to a landscape orientation.`,
        },
    },
    FRONT: {
        TAKE: "Take a photo of the front of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the front of your `,
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        TAKE_PASSPORT_CAPTURED_TITLE: "Got it!",
        TAKE_PASSPORT_CAPTURED_SUBTITLE: "Below should be a photo of the main page inside of your ",
        RETAKE_PASSPORT: "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        PROTIP: "Pro Tip",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED:
          `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
    },
    BACK: {
        TAKE: "Now take a photo of the back of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the back of your `,
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        ABOUTYOUINFO:
        "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED:
        `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
    },
    SELFIE: {
        TAKE: "Lastly, take a selfie.",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of you.`,
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
        ABOUTYOUINFO:
        "For best results, take your selfie in a well lit area.",
        ABOUTYOUINFO_CAPTURED:
        `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new selfie.`,
        NEXT: "Next",
    },
    REVIEW: {
        SECTION_HEADER_PERSONAL: `Personal`,
        SECTION_HEADER_ADDRESS: `Residential address`,
        CONTINUE_CTA: `Continue`,
        DESKTOP: {
            HEADER_TITLE: `Identity verification`,
            SUBTITLE: `Please enter your details.`,
        },
        MOBILE: {
            HEADER_TITLE: `Thank you`,
            BODY_0: `Please review the information as it was scanned.`,
            BODY_1: `You can update your info should you need to.`,
        },
        FORM: {
            FIRST_NAME_LABEL: `First name`,
            FIRST_NAME_PLACEHOLDER: `First name`,
            MIDDLE_NAME_LABEL: `Middle name`,
            MIDDLE_NAME_PLACEHOLDER: `Middle name`,
            LAST_NAME_LABEL: `Last name`,
            LAST_NAME_PLACEHOLDER: `Last name`,
            DOB_LABEL: `Date of birth`,
            DOB_PLACEHOLDER: `YYYY-MM-DD`,
            STREET_ADDRESS_LABEL: `Street address`,
            STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
            CITY_LABEL: `City`,
            CITY_PLACEHOLDER: `City`,
            PROVINCE_LABEL: `Province / Territory`,
            PROVINCE_PLACEHOLDER: `Province / Territory`,
            POSTAL_CODE_LABEL: `Postal code`,
            POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
            HEADER_TITLE: `Did the information from your ID scan correctly?`,
            YES: `Yes`,
            NO: `No`,
            UPDATE_INFO: `Be sure to update your information so it is correct and current, then hit "Continue".`,
        },
        ERROR_CODE: {
            FIRST_NAME_REQUIRED: `*First name is required`,
            LAST_NAME_REQUIRED: `*Last name is required`,
            DOB_REQUIRED: `*Date of birth is required`,
            ADDRESS_REQUIRED: `*Residential address is required`,
            CITY_REQUIRED: `*City is required`,
            PROVINCE_REQUIRED: `*Province / territory is required`,
            POSTAL_CODE_REQUIRED: `*Postal Code is required`,
            POSTAL_CODE_BAD_FORMAT: `Postal code does not appear to be valid. Please check for errors.`,
            NUMBERS_ERROR: `Numbers and some special characters are not accepted in names.`,
        },
    },
    END: {
        IDENTITY_SUCCESS: {
            HEADER_TITLE: `Success!`,
            BODY_0: `We have received your identification information and a member of the ${FIInformation.FI_DISPLAY_NAME} team will review the details and be in touch with you.`,
            BODY_1: `If you have any questions or concerns, please contact us at`,
            BODY_TEL: `${FIInformation.FI_TEL_PRETTY}`,
            BODY_1_2: `or`,
            BODY_EMAIL: `${FIInformation.FI_EMAIL_PRETTY}.`,
            FOOTER: `You can now close this browser window.`,
        },
        IDENTITY_FAIL: {
            HEADER_TITLE: `Hmm...`,
            BODY_0: `Unfortunately we were unable to automatically verify you at this time.`,
            BODY_1: `A confirmation email has been sent to your email address. A representative from ${FIInformation.FI_DISPLAY_NAME} will reach out to you soon.`,
            FOOTER: `You can now close this browser window.`,
        },
        LEAVING: {
            HEADER_TITLE: `Leaving us?`,
            BODY_0: `You are now finished with automatic verification. Any information you may have entered has been removed from our systems.`,
            FOOTER: `You can now close this browser window.`,
        },
    },
    MODAL: {
        CLOSE: "Close",
        OK: "OK",
    },
    MODAL_COMPATIBLE: {
        HEADER_TITLE: `ID Compatability Check`,
        BODY_0: `Our system uses machine reading and AI technologies to authentic ID documents. To prevent application delays, please upload government issued ID documents compatible with our system.`,
        BODY_1: `Compatible government issued ID documents have:`,
        BODY_1_ITEM_0: `your photo`,
        BODY_1_ITEM_1: `your date of birth`,
        BODY_1_ITEM_2: `machine readable fonts`,
        BODY_1_ITEM_3: `a bar code`,
        BODY_2: `Examples of compatible government issued IDs:`,
        BODY_2_ITEM_0: `Provincial / Territorial Issued Enhanced Driver's License`,
        BODY_2_ITEM_1: `Provincial / Territorial Issued Photo ID card`,
        BODY_2_ITEM_2: `Federally issued Secure Certificate of Indian Status`,
        BODY_2_ITEM_3: `Canadian Citizenship card`,
        BODY_2_ITEM_4: `Passport`,
        BODY_2_ITEM_5: `Candaian Forces Identification cards`,
        BODY_2_ITEM_6: `Permanent Resident card`,
        BODY_2_ITEM_7: `New (2023) Provincial / Territorial Issued PAL Firearms License`,
        BODY_2_ITEM_8: `USA/CAN Nexus card`,
        BODY_3_IMAGE_HEADER: `Example:`,
        BODY_3_IMAGE_ALT: `Example of a federally issued Certificate of Indian Status card.`,
        BODY_3: `NOTE: Some IDs may be government issued, valid, and legal but <b>cannot be validated via automatic verification</b>. Uploading these will cause delays in your applicaiton and may require further verification.`,
        BODY_4: `DO NOT UPLOAD THESE KIND.`,
        BODY_5:`Incompatible government issued ID documents:`,
        BODY_5_ITEM_0: `may be plastic laminated`,
        BODY_5_ITEM_1: `use fonts or scripts like "hand-writing" which are not machine readable`,
        BODY_6:`Examples of incompatible government issued IDs:`,
        BODY_6_ITEM_0: `older, laminated Canadian Citizenship card`,
        BODY_6_ITEM_1: `older (pre 2023) POL or PAL firearms licenses`,
        BODY_6_ITEM_2: `Provincial / Territorial, regional or nation / band issued Indian Status cards`,
        BODY_7:`Examples:`,
        BODY_7_IMAGE_ALT:`Example of an out of date and unacceptable Certificate of Canadian Citizenship.`,
        BODY_7_IMAGE_ALT_1:`Example of an unacceptable regionally issued Indian Status card.`,
    },
    MODAL_SCANTIPS: {
        HEADER_TITLE: "ID Scan Tips",
        POPUPINFO: "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
        POPUPINFO2: "Use a well and evenly lit area.",
        POPUPINFO3: "Ensure all data is readable.",
        POPUPINFO4: "Avoid reflections or glare.",
    },
    MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "Selfie Tips",
        POPUPINFO: "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a well and evenly lit area.",
        POPUPINFO2: "Avoid shadowing of your face.",
        POPUPINFO3: "Avoid reflections or glare.",
        POPUPINFO4: `If your selfie looks blurry in the preview, tap "RETAKE".`,
    },
    MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "Success!",
        BODY_0: `Your information has been submitted successfully.`,
        BODY_1: `An email confirmation has been sent to the email address you provided and a representative from ${FIInformation.FI_DISPLAY_NAME} will be in touch with you shortly.`,
    },
    MODAL_LEAVING_APP: {
        HEADER_TITLE: "Leaving so soon?",
        BODY_0: `Leaving now means we will not be able to automatically verify you.`,
        BODY_1: `Are you sure you want to leave now?`,
        CANCEL_CTA: `Cancel`,
        LEAVE_CTA: `Leave now`,
    },
    MONTHS: {
        JAN: `Jan`,
        FEB: `Feb`,
        MAR: `Mar`,
        APR: `Apr`,
        MAY: `May`,
        JUN: `Jun`,
        JUL: `Jul`,
        AUG: `Aug`,
        SEP: `Sep`,
        OCT: `Oct`,
        NOV: `Nov`,
        DEC: `Dec`,
    },
    GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
}