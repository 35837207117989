import {Route} from "react-router-dom";
import Main from "./containers/Main";
import Switch from "react-bootstrap/Switch";
import Id from "./containers/Id";
import Info from "./containers/Info";
import Review from "./containers/Review";
import Verify from "./containers/Verify";
import End from "./containers/End";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.scss';
import {useEffect} from "react";
import {GlobalDebug} from "./lib/remove-consoles";

function App() {

    useEffect(() => {
        (process.env.NODE_ENV === "production" ||
            process.env.REACT_APP_ENV === "STAGING") &&
        GlobalDebug(true);
    }, []);

    return (
        <Switch>
            <Route path={"/id"} component={Id}/>
            <Route path={"/info"} component={Info}/>
            <Route path={"/review"} component={Review}/>
            <Route path={"/verify"} component={Verify}/>
            <Route path={"/end"} component={End}/>
            <Route path={"/"} exact component={Main}/>
        </Switch>
    );
}

export default App;
