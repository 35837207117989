import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Component} from "react";
import axios from "axios";
import {URL_REGISTER, VERIFY, FIInformation} from "../lib/Constants";
import { STRINGS } from '../lib/Strings';
import Layout from "../components/Layout";
import NumberFormat from "react-number-format";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// Schema for yup
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(STRINGS.INFO.ERROR_CODE.EMAIL_VALID)
        .max(100, STRINGS.INFO.ERROR_CODE.EMAIL_CHARACTER_LIMIT)
        .required(STRINGS.INFO.ERROR_CODE.EMAIL_REQUIRED),
    phone: Yup.string()
        .matches(phoneRegExp, STRINGS.INFO.ERROR_CODE.PHONE_VALID)
        .required(STRINGS.INFO.ERROR_CODE.PHONE_REQUIRED),
    terms: Yup.boolean()
        .oneOf([true], STRINGS.INFO.ERROR_CODE.TERMS_REQUIRED),
});


class Info extends Component {
    state = {
        verifyError: null,
        showModal: false,
        showSpinner: false,
    };

    sendVerificationCode(email, phone) {
        const {history} = this.props;
        this.setState({
            showSpinner: true
        }, () => {
            const msisdn = "1" + phone.replace(VERIFY.CONTACT_NO_PATTERN, "");
            sessionStorage.setItem("EMAIL", email);
            sessionStorage.setItem("PHONE", msisdn);
            axios.post(URL_REGISTER, {
                email,
                msisdn,
                otpTo: "msisdn"
            }).then(response => {
                console.log(response);
                sessionStorage.setItem("OTP_ID", response.data.otpId);
                history.push("/verify");
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                if (this._ismounted) {
                    this.setState({
                        showSpinner: true
                    });
                }
            });
        });
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        const {history} = this.props;
        const {showSpinner, showModal, frontImage} = this.state;
        return (
            <Layout showSpinner={showSpinner} history={history}>
                <Formik
                    initialValues={{email: "", phone: "", terms: false}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        this.sendVerificationCode(values.email, values.phone);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Container className="form-fields">
                                <Row>
                                    <Col sm={12}><h1>{STRINGS.INFO.HEADER_TITLE}</h1></Col>
                                    <Col sm={12}><h3>{STRINGS.INFO.SUBHEADER_TITLE}</h3></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>

                                        <Form.Group controlId="formEmail">
                                            <Form.Label>{STRINGS.INFO.EMAIL_LABEL}</Form.Label>
                                            <Form.Control size="lg" type="email" name="email"
                                                          placeholder={STRINGS.INFO.EMAIL_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.email}
                                                          className={touched.email && errors.email ? "has-error" : null}/>
                                            {touched.email && errors.email ? (
                                                <div className="error-message">{errors.email}</div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group controlId="formPhone">
                                            <Form.Label>{STRINGS.INFO.MOBILE_NUMBER_LABEL}</Form.Label>
                                            <NumberFormat
                                                format={"(###) ###-####"}
                                                className={touched.phone && errors.phone ? "form-control form-control-lg has-error" : "form-control form-control-lg"}
                                                type="tel"
                                                name="phone"
                                                value={values.phone}
                                                placeholder={STRINGS.INFO.MOBILE_NUMBER_PLACEHOLDER}
                                                onChange={handleChange}
                                            />

                                            {touched.phone && errors.phone ? (
                                                <div className="error-message">{errors.phone}</div>
                                            ) : null}
                                        </Form.Group>
                                        <div className="form-group" controlId="acceptTerms">
                                            <div className="form-check">
                                                <input
                                                    className={touched.terms && errors.terms ? "form-check-input has-error" : "form-check-input"}
                                                    type="checkbox"
                                                    name="terms"
                                                    id="terms"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.terms}
                                                />
                                                <label htmlFor='terms' className="form-check-label">
                                                    {STRINGS.INFO.TERMS_LINK_LABEL_0} <a
                                                    href={FIInformation.FI_CONDITIONS}
                                                    target="_blank"> {STRINGS.INFO.TERMS_LINK_LABEL_1}</a>
                                                    {STRINGS.INFO.TERMS_LINK_LABEL_2}
                                                </label>
                                            </div>
                                            {touched.terms && errors.terms ? (
                                                <div className="error-message">{errors.terms}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="submit-btn-container">
                                    <Col md={{span: 6, offset: 3}}>
                                        <Button type="submit" variant="primary" block size="lg" disabled={isSubmitting}>
                                        {STRINGS.INFO.CONTINUE_CTA}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </Layout>
        );
    }

}

export default Info;