import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Modal, Row} from 'react-bootstrap';
import React, {Component} from "react";
import {ID_LIST, IDEVERIFICATION_AUTH, IDVERIFICATION_FAILED_CODE, verifyDoc,} from "../lib/instnt";
import {INSTNT_TXN_ID, TEST_BACK, TEST_FRONT, TEST_SELFIE} from "../lib/test";
import {
    ATTEMPTS,
    ERROR_CODE,
    HTTP_STATUS,
    S_INSTNT_FINGER_PRINT,
    S_INSTNT_TXN_ID,
    URL_SUBMIT_VERIFY
} from "../lib/Constants";
import {appendScript} from "../lib/ExternalScript";
import Card from "react-bootstrap/Card";
import axios from "axios";
import axiosRetry from 'axios-retry';
import {STRINGS} from "../lib/Strings";
import Layout from "../components/Layout";
import {InstntDocumentProcessor, InstntSelfieProcessor, InstntSignupProvider} from "@instnt/instnt-react-js";

const verifyMessages = [
    STRINGS.ID_SELECT.VERIFICATION_0,
    STRINGS.ID_SELECT.VERIFICATION_0,
    STRINGS.ID_SELECT.VERIFICATION_1,
    STRINGS.ID_SELECT.VERIFICATION_1,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
    STRINGS.ID_SELECT.VERIFICATION_2,
];

let selectIdListElements = [];
selectIdListElements = ID_LIST.map((elem, idx) => {
    return (
        <option
            key={idx}
            value={idx}
            className="[ dropdown__option ]"
        >
            {elem.description}
        </option>
    );
});

const SCREEN = {
    IDSELECTION: "idSelection",
    SCANIDFRONT: "scanIdFront",
    SCANIDBACK: "scanIdBack",
    SELFIE: "selfie",
    REVIEW: "review",
};

const getFailedResponse = (reasonCodes) => {
    return IDVERIFICATION_FAILED_CODE[reasonCodes[0]];
};

const getUserCheckList = (response) => {
    let authCheckList = getAuthCheckList(response);
    if (
        (!response.matchSelfie || response.matchSelfie !== "Verified") &&
        response.authentication !== IDEVERIFICATION_AUTH.VERIFIED_BUT_NOT_PASS
    ) {
        authCheckList.captureSelfie = true;
    }
    return authCheckList;
};

const getAuthCheckList = (response) => {
    switch (response.authentication) {
        case IDEVERIFICATION_AUTH.VERIFIED:
        case IDEVERIFICATION_AUTH.FRAUD_SHIELD:
            return {
                profile: true,
                selectId: false,
                captureFrontId: false,
                captureBackId: false,
                captureSelfie: false,
            };
        case IDEVERIFICATION_AUTH.RECAPTURE_SELFIE:
            return {
                profile: true,
                selectId: false,
                captureFrontId: false,
                captureBackId: false,
                captureSelfie: true,
            };
        case IDEVERIFICATION_AUTH.RECAPTURE_ID:
            return {
                profile: true,
                selectId: false,
                captureFrontId: true,
                captureBackId: true,
                // captureFrontId: false,
                // captureBackId: false,
                captureSelfie: false,
            };
        case IDEVERIFICATION_AUTH.FAILED:
            return getFailedResponse(response.reasonCodes);
        case IDEVERIFICATION_AUTH.RECAPTURE_ALL:
        case IDEVERIFICATION_AUTH.ID_EXPIRATION:
        case IDEVERIFICATION_AUTH.UNSUPPORTED_ID:
        case IDEVERIFICATION_AUTH.POTENTIAL:
        case IDEVERIFICATION_AUTH.SUBMISSION_ERROR:
        default:
            return {
                profile: true,
                selectId: true,
                captureFrontId: true,
                captureBackId: true,
                captureSelfie: true,
            };
    }
};

class Id extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testOnly: process.env.REACT_APP_TEST_ONLY,
            checkInList: IDVERIFICATION_FAILED_CODE.INITIAL,
            selectedOption: STRINGS.ID_SELECT.ID_SELECT_LABEL,
            frontImage: null,
            backImage: null,
            selfieImage: null,
            showId: true,
            showSelfie: false,
            showBack: false,
            showFont: false,
            scanError: null,
            frontError: null,
            backError: null,
            selfieError: null,
            instnttxnid: null,
            showSpinner: false,
            showModal: false,
            modalMessage: null,
            isProcessing: false,
            spinnerMessage: "",
            processingCount: ATTEMPTS,
            retake: false,
            retakeFrontComplete: false,
            retakeBackComplete: false,
            retakeSelfieComplete: false,
            doCapture: false,
            capture: null,
            idIndex: '',
            idType: '',
            idDesc: '',
            selectIDError: '',
            showCompatibleModal: false,
            showScanTipsModal: false,
            showScanTipsFaceModal: false,
            showModalPassport: false,
            showModalFront: false,
            showModalBack: false,
            showModalSelfie: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectId = this.handleSelectId.bind(this);
        this.handleShowCompatibleModal = this.handleShowCompatibleModal.bind(this);
        this.handleCloseCompatibleModal = this.handleCloseCompatibleModal.bind(this);
    }
    handleShowCompatibleModal() {
        console.log('Opening modal');
        this.setState({
            showCompatibleModal: true,
        });
    }

    handleCloseCompatibleModal() {
        console.log('Closing modal');
        this.setState({
            showCompatibleModal: false,
        });
    }
    handleShowScanTipsModal = () => {
        this.setState({
            showScanTipsModal: true
        });
    }
    handleCloseScanTipsModal = () => {
        this.setState({
            showScanTipsModal: false
        });
    }
    handleShowScanTipsFaceModal = () => {
        this.setState({
            showScanTipsFaceModal: true
        });
    }
    handleCloseScanTipsFaceModal = () => {
        this.setState({
            showScanTipsFaceModal: false
        });
    }
    handleShowModalPassport = () => {
        this.setState({ showModalPassport: true });
        };
    handleCloseModalPassport = () => {
        this.setState({ showModalPassport: false });
    };

    handleShowModalFront = () => {
        this.setState({ showModalFront: true });
        };
    handleCloseModalFront = () => {
        this.setState({ showModalFront: false });
    };

    handleShowModalBack = () => {
        this.setState({ showModalBack: true });
        };
    handleCloseModalBack = () => {
        this.setState({ showModalBack: false });
    };

    handleShowModalSelfie = () => {
        this.setState({ showModalSelfie: true });
        };
    handleCloseModalSelfie = () => {
        this.setState({ showModalSelfie: false });
    };

    componentDidMount() {
        this._ismounted = true;
        const {history} = this.props;
        const token = sessionStorage.getItem("TOKEN");
        if (!token) {
            history.push("/");
        } else {
            //appendScript("/instnt.js");
            this.setState({capture: window.IDMetricsCaptureFramework});
            const idIndex = sessionStorage.getItem("ID_INDEX");
            const idType = sessionStorage.getItem("ID_TYPE");
            const idDesc = sessionStorage.getItem("ID_DESC");
            if (idIndex) {
                this.setState({
                    idIndex,
                    idType,
                    idDesc,
                })
            } 
            else {
                //default to canada passport
                sessionStorage.setItem("ID_INDEX", "0");
                sessionStorage.setItem("ID_TYPE", "Passport");
                sessionStorage.setItem("ID_DESC", "Canadian passport");
                console.log("guess we called the default");
            }
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    handleSelect(event) {
        const selectedId = event.target.value;
        const ID = ID_LIST[selectedId];
    
        console.log("handleSelect", ID);
    
        // Reset the error state whenever a new selection is made
        this.setState({ selectIDError: '' });
    
        if (!ID) { // If ID is undefined (e.g., 'wrongChoice')
            console.log("error triggered");
            this.setState({ selectIDError: STRINGS.ID_SELECT.ERROR.ERROR_SELECT_ID });

            // Clear relevant sessionStorage items
            sessionStorage.removeItem("ID_INDEX");
            sessionStorage.removeItem("ID_TYPE");
            sessionStorage.removeItem("ID_DESC");
            console.log("Previously selected ID should be removed from cache/session storage so how come I can contine????");
        } else {
            sessionStorage.setItem("ID_INDEX", event.target.value);
            sessionStorage.setItem("ID_TYPE", ID.type);
            sessionStorage.setItem("ID_DESC", ID.description);
            this.setState({
                idIndex: event.target.value,
                idType: ID.type,
                idDesc: ID.description,
            });
        }
    }
    

    handleSelectId(event) {
        if (event) event.preventDefault();

        if (this.state.idIndex === '' || this.state.idIndex === 'wrongChoice') {
            this.setState({ selectIDError: STRINGS.ID_SELECT.ERROR.ERROR_SELECT_ID });

        } else {
            const session_fingerprint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
            const fingerprint = document.getElementById("fingerprint_txt");
            if(!session_fingerprint){
                sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint?.value);
            }
            this.getNextActivePart(this.state.checkInList);
        }
    }

    handleFront(event) {
        if (event) event.preventDefault();
        let frontImage = this.state.frontImage;
        if (!frontImage && this.state.testOnly) {
            frontImage = TEST_FRONT;
        }
        if (frontImage) {
            this.setState({frontImage, scanError: ""});
            this.getNextActivePart(this.state.checkInList);
        } else {
            this.setState({
                frontError: STRINGS.ID_SELECT.ERROR.ERROR_FRONT_ID
            })
        }
    }

    handleBack(event) {
        if (event) event.preventDefault();
        let backImage = this.state.backImage;
        if (!backImage && this.state.testOnly) {
            backImage = TEST_BACK;
        }
        if (backImage) {
            this.setState({backImage, scanError: ""});
            this.getNextActivePart(this.state.checkInList);
        } else {
            this.setState({
                backError: STRINGS.ID_SELECT.ERROR.ERROR_BACK_ID
            })
        }
    }

    async submitVerify() {
        const {history} = this.props;
        const {instnttxnid} = this.state;
        this.setState({
            showSpinner: true,
            spinnerMessage: verifyMessages[0],
        });
        const otpId = sessionStorage.getItem("OTP_ID");
        const token = sessionStorage.getItem("TOKEN");
        axiosRetry(axios, {
            retries: verifyMessages.length, // number of retries
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                this.setState({
                    spinnerMessage: verifyMessages[retryCount],
                });
                return retryCount * 4000; // time interval between retries
            },
            retryCondition: (error) => {
                console.log(error.response);
                // if retry condition is not specified, by default idempotent requests are retried
                return error.response?.status === 500 ||
                    error.response?.data?.code === ERROR_CODE.CANNOT_DECRYPT ||
                    error.response?.data?.code === ERROR_CODE.FAIL_ASSERTION ||
                    error.response?.data?.code === ERROR_CODE.DATA_NOT_AVAILABLE;

            },
        });

        await axios.post(URL_SUBMIT_VERIFY + "/" + otpId, {instnttxnid}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            this.setState({
                showSpinner: false,
                spinnerMessage: "",
            });
            this.handleSubmitIDVerify(response);
        }).catch((err) => {
            console.log(err.status, err.response);
            console.log(`API call failed with status code: ${err.response.status}`);
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end?code=" + err.response.status);
        });
    }

    handleSelfie(event) {
        console.log("handleSelfie");
        const {instnttxnid, idType} = this.state;
        if (event) event.preventDefault();
        let selfieImage = this.state.selfieImage;
        if (!selfieImage && this.state.testOnly) {
            selfieImage = TEST_SELFIE;
        }
        ;
        if (selfieImage) {
            sessionStorage.setItem("SELFIE_IMAGE", selfieImage);
            const instnt_token = window.instnt.getToken();
            let request = {instnttxnid, formKey: process.env.REACT_APP_INSTNT_KEY, documentType: idType};
            this.setState({
                isProcessing: true,
                showSpinner: true,
                spinnerMessage: STRINGS.ID_SELECT.SPINNER_MSG,
            });
            verifyDoc(request).then((result) => {
                console.log(result);
                setTimeout(function () {
                    this.submitVerify(result);
                }.bind(this), 3000);
            });

            // router.push("/review");
        } else {
            this.setState({
                selfieError: STRINGS.ID_SELECT.ERROR.ERROR_SELFIE
            })
        }

    }

    onEventHandler = (event) => {
        console.log("Instnt event: ", event);
        const {checkInList, testOnly} = this.state;
        switch (event.type) {
            case "transaction.initiated":
                const fingerprint = document.getElementById("fingerprint_txt");
                console.log("testOnly", testOnly);
                if (testOnly) {
                    this.setState({
                        insnt: event.data.instnt,
                        instnttxnid: INSTNT_TXN_ID,
                        fingerprint: fingerprint?.value ? fingerprint.value : event.data.instnt.fingerprint_txt,
                    });
                    sessionStorage.setItem(S_INSTNT_TXN_ID, INSTNT_TXN_ID);
                    console.log("new instnttxnid", INSTNT_TXN_ID);
                } else {
                    this.setState({
                        insnt: event.data.instnt,
                        instnttxnid: event.data.instnt.instnttxnid,
                        fingerprint: fingerprint?.value ? fingerprint.value : event.data.instnt.fingerprint_txt,
                    });
                    sessionStorage.setItem(S_INSTNT_TXN_ID, event.data.instnt.instnttxnid);
                    console.log("new instnttxnid", event.data.instnt.instnttxnid);
                }
                sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint.value);
                break;
            case "document.captured":
                this.setState({
                    documentSettings: event.data.documentSettings,
                    selfieSettings: event.data.selfieSettings,
                    captureResult: event.data.captureResult,
                    doCapture: false,
                });
                if (checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({frontImage: event.data.captureResult.result, scanError: ""})
                } else if (checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({backImage: event.data.captureResult.result, scanError: ""})
                } else if (checkInList.captureSelfie && !checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId) {
                    this.setState({selfieImage: event.data.captureResult.result, scanError: ""})
                }
                break;
            case "document.capture-cancelled":
                this.setState({doCapture: false});
                break;
            default:
                console.log("unhandled instnt event ", event);
        }
    };

    scanFrontID = () => {
        this.setState({doCapture: true});
    };

    scanBackID = () => {
        this.setState({doCapture: true});
    };

    scanSelfie = () => {
        this.setState({doCapture: true});
    };

    convertBase64ImageToString = (dataUri) => {
        let byteString;
        if (dataUri.split(",")[0].indexOf("base64") <= 0)
            byteString = atob(dataUri.split(",")[1]);
        else byteString = unescape(dataUri.split(",")[1]);

        return byteString;
    }

    setFormData = (formData) => {
        console.log(formData);
        // const { postalCode } = formData;
        // const postalCodeFormatted = postalCode
        //     ? conformToMask(postalCode, AppConstants.ABOUT_YOU.CA_POSTALMASK, {
        //         guide: false,
        //     }).conformedValue
        //     : postalCode;
        this.setState({
            formData: {...formData},
        });
    };

    getNextActivePart = (checkList) => {
        console.log("getNextActivePart", checkList);
        const {idType} = this.state;
        const {
            selectId,
            captureFrontId,
            captureBackId,
            captureSelfie,
            profile,
        } = checkList;
        let checkInList = null;
        if (selectId) {
            checkInList = {
                selectId: false,
                captureFrontId,
                captureBackId,
                captureSelfie,
                profile,
            };
        } else if (captureFrontId) {
            checkInList = {
                selectId,
                captureFrontId: false,
                captureBackId,
                captureSelfie,
                profile,
            };
        } else if (captureBackId) {
            checkInList = {
                selectId,
                captureFrontId,
                captureBackId: false,
                captureSelfie,
                profile,
            };
        } else if (captureSelfie) {
            checkInList = {
                selectId,
                captureFrontId,
                captureBackId,
                captureSelfie: false,
                profile,
            };
        }

        if (idType === "Passport") {
            checkInList.captureBackId = false;
        }

        if (selectId === false && captureFrontId === false && captureBackId === false && captureSelfie === false) {
            this.handleSelfie();
        } else if (checkInList.selectId === false && checkInList.captureFrontId === false && checkInList.captureBackId === false && checkInList.captureSelfie === false) {
            this.handleSelfie();
        } else {
            if (!checkInList) {
                checkInList = {
                    selectId,
                    captureFrontId,
                    captureBackId,
                    captureSelfie,
                    profile,
                }
            }

            console.log("updating checkInList state", checkInList);
            this.setState({checkInList});
        }
    };

    handleSubmitIDVerify = (response) => {
        console.log("handleSubmitIDVerify", response);
        const {history} = this.props;
        const {processingCount} = this.state;
        if (!response) {
            console.log("undefined submit verify response");
            sessionStorage.setItem("END_FLAG", "ERROR");
            history.push("/end?code=500");
        } else if (response.status === HTTP_STATUS.OK) {
            sessionStorage.setItem("INSTNT_DATA", JSON.stringify(response.data));
            if (processingCount - 1 > 0) {
                const checkInList = getUserCheckList(response.data);
                if (!checkInList.captureFrontId && !checkInList.captureBackId && !checkInList.captureSelfie) {
                    history.push("/review");
                } else {
                    const count = processingCount - 1;
                    if (checkInList.captureFrontId) {
                        this.setState({
                            frontImage: null
                        });
                    }
                    ;
                    if (checkInList.captureBackId) {
                        this.setState({
                            backImage: null
                        });
                    }
                    ;
                    if (checkInList.captureSelfie) {
                        this.setState({
                            selfieImage: null
                        });
                    }
                    ;
                    this.setState({
                        checkInList,
                        processingCount: count,
                        retake: true,
                    });
                    let errorMessage = response.data?.authentication;
                    if (errorMessage.startsWith(IDEVERIFICATION_AUTH.DOCUMENT_NOT_PASS)) {
                        this.setState({
                            showModal: true,
                            modalMessage: STRINGS.GENERIC_ERROR_MESSAGE,
                        });
                    } else if (
                        response.data?.authentication === IDEVERIFICATION_AUTH.VERIFIED &&
                        response.data?.matchSelfie
                    ) {
                        this.setState({
                            showModal: true,
                            modalMessage: response.data?.matchSelfie,
                        });
                    }
                }
            } else {
                history.push("/review");
            }
        } else {
            history.push("/");
        }
    };

    handleClose() {
        this.setState({
            showModal: false,
            modalMessage: null,
        });
    }

    render() {
        const {
            showSpinner,
            frontImage,
            backImage,
            selfieImage,
            showModal,
            modalMessage,
            isProcessing,
            checkInList,
            spinnerMessage,
            idType,
            idDesc,
            retake,
            retakeFrontComplete,
            retakeBackComplete,
            retakeSelfieComplete,
            doCapture,
            testOnly,
            showCompatibleModal,
            showScanTipsModal,
            showScanTipsFaceModal,
            showModalPassport,
            showModalFront,
            showModalBack,
            showModalSelfie,
        } = this.state;
        const {history} = this.props;

        const frontLicenseSettings = {
            documentType: idType,
            documentSide: "Front",
            frontFocusThreshold: 30,
            frontGlareThreshold: 2.5,
            frontCaptureAttempts: 4,
            captureMode: "Auto",
            overlayText: STRINGS.ID_SELECT.OVERLAY_FRONT_ID,
            overlayTextAuto: STRINGS.ID_SELECT.OVERLAY_FRONT_ID_AUTOTAKE,
            overlayColor: "yellow",
            orientationErrorText: STRINGS.ID_SELECT.ERROR.ERROR_PORTRAIT,
            enableFaceDetection: true,
            setManualTimeout: 8,
            backFocusThreshold: 30,
            backGlareThreshold: 2.5,
            backCaptureAttempts: 4,
            isBarcodeDetectedEnabled: false,
            enableLocationDetection: false,
        };
        const backLicenseSettings = {
            documentType: idType,
            documentSide: "Back",
            frontFocusThreshold: 30,
            frontGlareThreshold: 2.5,
            frontCaptureAttempts: 4,
            captureMode: "Auto",
            overlayText: STRINGS.ID_SELECT.OVERLAY_BACK_ID,
            overlayTextAuto: STRINGS.ID_SELECT.OVERLAY_BACK_ID_AUTOTAKE,
            overlayColor: "yellow",
            orientationErrorText: STRINGS.ID_SELECT.ERROR.ERROR_PORTRAIT,
            enableFaceDetection: true,
            setManualTimeout: 8,
            backFocusThreshold: 30,
            backGlareThreshold: 2.5,
            backCaptureAttempts: 4,
            isBarcodeDetectedEnabled: false,
            enableLocationDetection: false,
        };
        const selfieSettings = {
            enableFarSelfie: true,
            selfieCaptureAttempt: 4,
            captureMode: "Auto",
            compressionType: "JPEG",
            compressionQuality: "50",
            useBackCamera: false,
            overlayText: STRINGS.ID_SELECT.OVERLAY_SELFIE,
            overlayTextAuto: STRINGS.ID_SELECT.OVERLAY_SELFIE_AUTOTAKE,
            overlayColor: "#808080",
            orientationErrorText: STRINGS.ID_SELECT.ERROR.ERROR_LANDSCAPE,
            enableFaceDetection: true,
            setManualTimeout: 8,
            enableLocationDetection: false,
        };
        return (
            <Layout showSpinner={showSpinner} history={history} spinnerMessage={spinnerMessage}>

                <Modal
                    show={showModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.handleClose.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        {STRINGS.ID_SELECT.ERROR.ERROR_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{modalMessage ? modalMessage : STRINGS.ID_SELECT.ERROR.ERROR_BODY}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    show={showCompatibleModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                    onHide={this.handleCloseCompatibleModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {STRINGS.MODAL_COMPATIBLE.HEADER_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{STRINGS.MODAL_COMPATIBLE.BODY_0}</p>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_1}</strong></p>
                            <ul>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_1_ITEM_0}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_1_ITEM_1}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_1_ITEM_2}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_1_ITEM_3}</li>
                            </ul>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_2}</strong></p>
                            <ul>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_0}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_1}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_2}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_3}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_4}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_5}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_6}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_7}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_2_ITEM_8}</li>
                            </ul>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_3_IMAGE_HEADER}</strong></p>
                        <img 
                            src="/idExample.png"
                            style={{ width: '100%', height: 'auto' }} 
                            alt={STRINGS.MODAL_COMPATIBLE.BODY_3_IMAGE_ALT} 
                        />
                        <p
                            dangerouslySetInnerHTML={{ __html: STRINGS.MODAL_COMPATIBLE.BODY_3 }}
                        />
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_4}</strong></p>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_5}</strong></p>
                            <ul>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_5_ITEM_0}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_5_ITEM_1}</li>
                            </ul>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_6}</strong></p>
                            <ul>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_6_ITEM_0}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_6_ITEM_1}</li>
                                <li>{STRINGS.MODAL_COMPATIBLE.BODY_6_ITEM_2}</li>
                            </ul>
                        <p><strong>{STRINGS.MODAL_COMPATIBLE.BODY_7}</strong></p>
                        <img 
                            src="/id1.png"
                            style={{ width: '100%', height: 'auto' }} 
                            alt={STRINGS.MODAL_COMPATIBLE.BODY_7_IMAGE_ALT} 
                        />
                        <img 
                            src="/id2.png"
                            style={{ width: '100%', height: 'auto' }} 
                            alt={STRINGS.MODAL_COMPATIBLE.BODY_7_IMAGE_ALT_1} 
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseCompatibleModal.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showScanTipsModal}
                    onHide={this.handleCloseScanTipsModal.bind(this)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {STRINGS.MODAL_SCANTIPS.HEADER_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
                        <div className="[ row ]">
                        <div className="[ col-12 ] [ id-scan-window-bg ]">
                            <div className="[ id-container ]">
                            <div className=" [ id-scan-window ]"></div>
                            <div className=" [ id-box ]"></div>
                            <div className="[ id-card ]">
                                <div className="[ row ]">
                                <div className="[ col-6 ]">
                                    <div className="[ id-pic ]"></div>
                                </div>
                                <div className="[ col-6 ]">
                                    <hr />
                                    <hr />
                                    <hr />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="[ col-12 ]">
                            <ul className="[ id-scan-tips ]">
                                <li>{STRINGS.MODAL_SCANTIPS.POPUPINFO}</li>
                                <li>{STRINGS.MODAL_SCANTIPS.POPUPINFO1}</li>
                                <li>{STRINGS.MODAL_SCANTIPS.POPUPINFO2}</li>
                                <li>{STRINGS.MODAL_SCANTIPS.POPUPINFO3}</li>
                                <li>{STRINGS.MODAL_SCANTIPS.POPUPINFO4}</li>
                            </ul>
                        </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseScanTipsModal.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showScanTipsFaceModal}
                    onHide={this.handleCloseScanTipsFaceModal.bind(this)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {STRINGS.MODAL_SCANTIPS_FACE.HEADER_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
                        <div className="[ row ]">
                        <div className="[ col-12 ] [ face-scan-window-bg ]">
                            <div className="[ face-container ]">
                            <div className=" [ face-scan-window ]"></div>
                            <div className=" [ face-box ]"></div>
                            <span className="[ fas fa-user-alt ]"></span>
                            </div>
                        </div>
                        <div className="[ col-12 ]">
                            <ul className="[ id-scan-tips ]">
                                <li>{STRINGS.MODAL_SCANTIPS_FACE.POPUPINFO}</li>
                                <li>{STRINGS.MODAL_SCANTIPS_FACE.POPUPINFO1}</li>
                                <li>{STRINGS.MODAL_SCANTIPS_FACE.POPUPINFO2}</li>
                                <li>{STRINGS.MODAL_SCANTIPS_FACE.POPUPINFO3}</li>
                                <li>{STRINGS.MODAL_SCANTIPS_FACE.POPUPINFO4}</li>
                            </ul>
                        </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseScanTipsFaceModal.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for passport */}
                <Modal className="picture-gallery" show={showModalPassport} onHide={this.handleCloseModalPassport} centered>
                    <Modal.Header closeButton> </Modal.Header>
                    <Modal.Body>
                        <img src={frontImage} alt="Enlarged passport" className="modal-image" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModalFront.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for front of image */}
                <Modal className="picture-gallery" show={showModalFront} onHide={this.handleCloseModalFront} centered>
                    <Modal.Header closeButton> </Modal.Header>
                    <Modal.Body>
                        <img src={frontImage} alt="Enlarged front of ID" className="modal-image" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModalFront.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for back of image */}
                <Modal className="picture-gallery" show={showModalBack} onHide={this.handleCloseModalBack} centered>
                    <Modal.Header closeButton> </Modal.Header>
                    <Modal.Body>
                        <img src={backImage} alt="Enlarged back of ID" className="modal-image" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModalBack.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for selfie */}
                <Modal className="picture-gallery" show={showModalSelfie} onHide={this.handleCloseModalSelfie} centered>
                    <Modal.Header closeButton> </Modal.Header>
                    <Modal.Body>
                        <img src={selfieImage} alt="Enlarged selfie" className="modal-image" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseModalSelfie.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>


                <InstntSignupProvider
                    formKey={process.env.REACT_APP_INSTNT_KEY}
                    onEvent={this.onEventHandler}
                    serviceURL={process.env.REACT_APP_INSTNT_URL}
                    idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
                >
                    {checkInList.selectId && (
                        <Container className="form-fields id-select">
                            <Row>
                                <Col sm={12}><h1>{STRINGS.ID_SELECT.HEADER_TITLE}</h1></Col>
                            </Row>
                            <Row className="cta-secondary">
                                <Col sm={12}>
                                    <Button variant="link" className="info-popUp" onClick={this.handleShowCompatibleModal}>
                                        {STRINGS.ID_SELECT.ID_COMPATIBLE}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId="selectId">
                                        <Form.Label>{STRINGS.ID_SELECT.ID_SELECT_LABE}</Form.Label>
                                        <Form.Control size="lg" as="select" onChange={this.handleSelect.bind(this)}>
                                            <option id="wrongChoice">{STRINGS.ID_SELECT.DUMMY_OPTION}</option>
                                            {selectIdListElements}
                                        </Form.Control>
    {/* EDWIN: If user selects "wrongChoice" and tries to continue, can you display this error. When user selects a real choice, error clears and they can move on. If they reselect the wrong option, then error redisplays and they can't move on*/}
                                        {this.state.selectIDError && (
                                            <div className="error-message">{this.state.selectIDError}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            onClick={this.handleSelectId.bind(this)}>
                                        {STRINGS.ID_SELECT.CONTINUE_CTA}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields id-front">
                            {doCapture && (
                                <InstntDocumentProcessor
                                    documentSettings={frontLicenseSettings}
                                    documentType={idType}
                                    documentSide="Front"
                                    captureMode="Auto"
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {frontImage && idType === "Passport" ? (
                                        <div className="photo-captured-message">
                                            <h1>{STRINGS.FRONT.TAKE_PASSPORT_CAPTURED_TITLE}</h1>
                                            <p>{STRINGS.FRONT.TAKE_PASSPORT_CAPTURED_SUBTITLE} {idDesc}.</p>
                                        </div>
                                    ) : frontImage ? (
                                        <div className="photo-captured-message">
                                            <h1>{STRINGS.FRONT.TAKE_CAPTURED_TITLE}</h1>
                                            <p>{STRINGS.FRONT.TAKE_CAPTURED_SUBTITLE} {idDesc}.</p>
                                        </div>
                                    ) : (
                                        <>
                                            {!retakeFrontComplete && idType !== "Passport" && (
                                                <h1 className={retake ? "error-message" : ""}>
                                                    {retake ? STRINGS.FRONT.RETAKE : STRINGS.FRONT.TAKE} {idDesc}.
                                                </h1>
                                            )}
                                            {!retakeFrontComplete && idType === "Passport" && (
                                                <h1 className={retake ? "error-message" : ""}>
                                                    {retake ? STRINGS.FRONT.RETAKE_PASSPORT : STRINGS.FRONT.TAKE_PASSPORT} {idDesc}.
                                                </h1>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            {!frontImage && idType === "Passport" && (
                            <>
                                <Row className="cta-secondary">
                                    <Col sm={12}>
                                        {STRINGS.FRONT.ABOUTYOUINFO}
                                        <Button variant="link" className="info-popUp" onClick={this.handleShowScanTipsModal}>
                                         {STRINGS.FRONT.ABOUTYOUINFO1}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center flex-grow-1">
                                    <Col sm={12} className="upload-buttons-container">
                                        <Card className="text-center upload-buttons" onClick={this.scanFrontID.bind(this)}>
                                            <Card.Body>
                                                <div className="card-inner passport">
                                                    <div className="passport-open icon-wrapper"></div>
                                                    <div className="passport-closed icon-wrapper"></div>
                                                </div>
                                            </Card.Body>
                                            <Card.Text>
                                                {STRINGS.FRONT.TAP_BEGIN}
                                            </Card.Text>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                            )}
                            {!frontImage && idType !== "Passport" && (
                            <>
                                <Row className="cta-secondary">
                                    <Col sm={12}>
                                        {STRINGS.FRONT.ABOUTYOUINFO}
                                        <Button variant="link" className="info-popUp" onClick={this.handleShowScanTipsModal}>
                                         {STRINGS.FRONT.ABOUTYOUINFO1}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center flex-grow-1">
                                    <Col sm={12} className="upload-buttons-container">
                                        <Card className="text-center upload-buttons" onClick={this.scanFrontID.bind(this)}>
                                            <Card.Body>
                                                <div className="card-inner no-animation">
                                                    <div className="card-back icon-wrapper"></div>
                                                    <div className="card-front icon-wrapper"></div>
                                                </div>
                                            </Card.Body>
                                            <Card.Text>
                                                {STRINGS.FRONT.TAP_BEGIN}
                                            </Card.Text>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                            )}
                            {frontImage && (
                            <>
                                <Row className="cta-secondary">
                                    <Col sm={12}>
                                        <p>{STRINGS.FRONT.ABOUTYOUINFO_CAPTURED}</p>
                                        <p>{STRINGS.FRONT.ABOUTYOUINFO1_CAPTURED}</p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center flex-grow-1">
                                    <Col sm={12} className="upload-buttons-container">
                                        <Card className="text-center upload-buttons">
                                            <Card.Body>
                                                <div className="picture icon-wrapper">
                                                    <Card.Img variant="top" src={frontImage} className="icon icon-frontID"/>
                                                    <Button className="magnify-icon" onClick={this.handleShowModalFront}>
                                                        <span className="[ fas fa-search-plus ]"></span>
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="retake">
                                    <Col sm={12}>
                                        <Button variant="link" onClick={this.scanFrontID.bind(this)}>
                                            {STRINGS.FRONT.TAP_RETAKE}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            )}
                            <Row>
                                <Col sm={12}>
                                    {this.state.frontError ? (
                                        <div className="error-message">{this.state.frontError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(frontImage || testOnly)}
                                            onClick={this.handleFront.bind(this)}>
                                        {STRINGS.FRONT.NEXT}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields id-back">
                            {doCapture && (
                                <InstntDocumentProcessor
                                    documentSettings={backLicenseSettings}
                                    documentType={idType}
                                    documentSide="Back"
                                    captureMode="Auto"
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {backImage ? (
                                        <div className="photo-captured-message">
                                            <h1>{STRINGS.BACK.TAKE_CAPTURED_TITLE}</h1>
                                            <p>{STRINGS.BACK.TAKE_CAPTURED_SUBTITLE} {idDesc}.</p>
                                        </div>
                                    ) : (
                                        <>
                                            {!retakeBackComplete && (
                                                <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.BACK.RETAKE : STRINGS.BACK.TAKE} {idDesc}.</h1>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            {!backImage && (
                                <>
                                    <Row className="cta-secondary">
                                        <Col sm={12}>
                                            {STRINGS.BACK.ABOUTYOUINFO}
                                            <Button variant="link" className="info-popUp" onClick={this.handleShowScanTipsModal}>
                                            {STRINGS.BACK.ABOUTYOUINFO1}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center flex-grow-1">
                                        <Col sm={12} className="upload-buttons-container">
                                            <Card className="text-center upload-buttons" onClick={this.scanBackID.bind(this)}>
                                                <Card.Body>
                                                    <div className="card-inner">
                                                        <div className="card-back icon-wrapper"></div>
                                                        <div className="card-front icon-wrapper"></div>
                                                    </div>
                                                </Card.Body>
                                                <Card.Text>
                                                    {STRINGS.FRONT.TAP_BEGIN}
                                                </Card.Text>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {backImage && (
                                <>
                                    <Row className="cta-secondary">
                                        <Col sm={12}>
                                            <p>{STRINGS.BACK.ABOUTYOUINFO_CAPTURED}</p>
                                            <p>{STRINGS.BACK.ABOUTYOUINFO1_CAPTURED}</p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center flex-grow-1">
                                        <Col sm={12} className="upload-buttons-container">
                                            <Card className="text-center upload-buttons">
                                                <Card.Body>
                                                    <div className="picture icon-wrapper">
                                                        <Card.Img variant="top" src={backImage} className="icon icon-backID"/>
                                                        <Button className="magnify-icon" onClick={this.handleShowModalBack}>
                                                            <span className="[ fas fa-search-plus ]"></span>
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="retake">
                                        <Col sm={12}>
                                            <Button variant="link" onClick={this.scanBackID.bind(this)}>
                                                {STRINGS.BACK.TAP_RETAKE}
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row>
                                <Col sm={12}>
                                    {this.state.backError ? (
                                        <div className="error-message">{this.state.backError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col sm={12}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(backImage || testOnly)}
                                            onClick={this.handleBack.bind(this)}>
                                        {STRINGS.BACK.NEXT}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {checkInList.captureSelfie && !checkInList.captureBackId && !checkInList.captureFrontId && !checkInList.selectId && (
                        <Container className="form-fields id-selfie">
                            {doCapture && (
                                <InstntSelfieProcessor
                                    documentType={idType}
                                    selfieSettings={selfieSettings}
                                />
                            )}
                            <Row>
                                <Col sm={12}>
                                    {selfieImage ? (
                                        <div className="photo-captured-message">
                                            <h1>{STRINGS.SELFIE.TAKE_CAPTURED_TITLE}</h1>
                                            <p>{STRINGS.SELFIE.TAKE_CAPTURED_SUBTITLE}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {!retakeSelfieComplete && (
                                                <h1 className={retake ? "error-message" : ""}>{retake ? STRINGS.SELFIE.RETAKE : STRINGS.SELFIE.TAKE}</h1>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            {!selfieImage && (
                                <>
                                    <Row className="cta-secondary">
                                        <Col sm={12}>
                                            {STRINGS.SELFIE.ABOUTYOUINFO}
                                            <Button variant="link" className="info-popUp" onClick={this.handleShowScanTipsFaceModal}>
                                            {STRINGS.SELFIE.ABOUTYOUINFO1}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center flex-grow-1">
                                        <Col sm={12} className="upload-buttons-container">
                                            <Card className="text-center upload-buttons" onClick={this.scanSelfie.bind(this)}>
                                                <Card.Body>
                                                    <div className="icon-wrapper">
                                                        <Card.Img variant="top" src="/selfie.svg" className="icon icon-selfie"/>
                                                    </div>
                                                    <Card.Text>
                                                        {STRINGS.SELFIE.TAP_BEGIN}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {selfieImage && (
                                <>
                                    <Row className="cta-secondary">
                                        <Col sm={12}>
                                            <p>{STRINGS.SELFIE.ABOUTYOUINFO_CAPTURED}</p>
                                            <p>{STRINGS.SELFIE.ABOUTYOUINFO1_CAPTURED}</p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center flex-grow-1">
                                        <Col sm={12} className="upload-buttons-container">
                                            <Card className="text-center upload-buttons">
                                                <Card.Body>
                                                    <div className="picture icon-wrapper">
                                                        <Card.Img variant="top" src={selfieImage} className="icon icon-selfie"/>
                                                        <Button className="magnify-icon" onClick={this.handleShowModalSelfie}>
                                                            <span className="[ fas fa-search-plus ]"></span>
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="retake">
                                        <Col sm={12}>
                                            <Button variant="link" onClick={this.scanSelfie.bind(this)}>
                                                {STRINGS.SELFIE.TAP_RETAKE}
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row>
                                <Col sm={12}>
                                    {this.state.selfieError ? (
                                        <div className="error-message">{this.state.selfieError}</div>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="submit-btn-container">
                                <Col md={{span: 6, offset: 3}}>
                                    <Button type="submit" variant="primary" block size="lg"
                                            disabled={!(selfieImage || testOnly)}
                                            onClick={this.handleSelfie.bind(this)}>
                                        {STRINGS.SELFIE.NEXT}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </InstntSignupProvider>

            </Layout>
        );
    }
}

export default Id;