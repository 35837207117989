import Button from 'react-bootstrap/Button';
import {Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {Component} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import axios from "axios";
import {API_KEY, URL_REGISTER, URL_VERIFY} from "../lib/Constants";
import { STRINGS } from '../lib/Strings';
import Layout from "../components/Layout";
import {isMobile} from "react-device-detect";

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required("*Verification Code is required")
});

class Verify extends Component {
    state = {
        verifyError: null,
        showModal: false,
        showSpinner: false,
    };

    resend() {
        const {history} = this.props;
        this.setState({
            verifyError: null,
            showSpinner: true
        }, () => {
            const email = sessionStorage.getItem("EMAIL");
            const msisdn = sessionStorage.getItem("PHONE");
            axios.post(URL_REGISTER, {
                email,
                msisdn,
                otpTo: "msisdn",
                apiKey: API_KEY,
            }).then(response => {
                console.log(response);
                sessionStorage.setItem("OTP_ID", response.data.otpId);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    showSpinner: false
                });
            });
        });
    }

    verifyCode(otp, setFieldValue, setSubmitting) {
        const {history} = this.props;
        this.setState({
            showSpinner: true
        }, () => {
            const otpId = sessionStorage.getItem("OTP_ID");
            axios.post(URL_VERIFY, {otpId, otp, apiKey: API_KEY}).then(response => {
                console.log(response);
                sessionStorage.setItem("TOKEN", response.data.token);
                if (isMobile) {
                    history.push("/id")
                } else {
                    history.push("/review");
                }
            }).catch(error => {
                console.log("error verify", error);
                setFieldValue("code", "");
                setSubmitting(false);
                this.setState({
                    showModal: true
                })
            }).finally(() => {
                if (this._ismounted) {
                    this.setState({
                        showSpinner: false
                    });
                }
            });
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            verifyError: null
        };
    }

    componentDidMount() {
        const {history} = this.props;
        const otpId = sessionStorage.getItem("OTP_ID");
        if (!otpId) {
            history.push("/");
        } else {
            this._ismounted = true;
            const {router} = this.props;
            if (!sessionStorage.getItem("EMAIL")) {
                router.push("/");
            }
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {history} = this.props;
        const {showSpinner, showModal, frontImage} = this.state;
        return (
            <Layout showSpinner={showSpinner} history={history}>
                <Modal
                    show={showModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.handleClose.bind(this)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        {STRINGS.VERIFY.ERROR.ERROR_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{STRINGS.VERIFY.ERROR.ERROR_BODY_0}</p>
                        <p>{STRINGS.VERIFY.ERROR.ERROR_BODY_1}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose.bind(this)}>{STRINGS.MODAL.OK}</Button>
                    </Modal.Footer>
                </Modal>
                <Formik
                    initialValues={{code: ""}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm, setFieldValue}) => {
                        setSubmitting(true);
                        this.verifyCode(values.code, setFieldValue, setSubmitting);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Container className="form-fields">
                                <Row>
                                    <Col sm={12}><h1>{STRINGS.VERIFY.HEADER_TITLE}</h1></Col>
                                    <Col sm={12}><h3>{STRINGS.VERIFY.SUBHEADER_TITLE}</h3></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="formCode">
                                            <Form.Label>{STRINGS.VERIFY.OTP_LABEL}</Form.Label>
                                            <Form.Control size="lg" type="text"
                                                          name="code"
                                                          placeholder={STRINGS.VERIFY.OTP_PLACEHOLDER}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.code}
                                                          className={touched.code && errors.code ? "has-error" : null}/>
                                            {touched.code && errors.code ? (
                                                <div className="error-message">{errors.code}</div>
                                            ) : null}
                                            {this.state.verifyError ? (
                                                <div className="error-message">{STRINGS.VERIFY.ERROR.OTP_INLINE_ERROR}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="cta-secondary">
                                    <Col sm={12}><h2>{STRINGS.VERIFY.RESEND_TITLE}</h2></Col>
                                    <Col sm={12}>
                                        <Button variant="link" onClick={() => this.resend()}>{STRINGS.VERIFY.RESEND_OTP}</Button>
                                    </Col>
                                </Row>
                                <Row className="submit-btn-container">
                                    <Col md={{span: 6, offset: 3}}>
                                        <Button type="submit" variant="primary" block size="lg" disabled={isSubmitting}>
                                        {STRINGS.VERIFY.CONTINUE_CTA}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </Layout>
        );
    }
}

export default Verify